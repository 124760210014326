<template>
  <div>
    <b-card>
      <h3 class="font-weight-bold mb-1">Message Welcome</h3>
      <b-row>
        <b-col>
          <b-form-group label="Message in Spanish" label-for="textareaw-1">
            <b-form-textarea
              v-model="WelcomeSmsEs"
              :disabled="isDisable"
              id="textareaw-1"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              style="height: 160px; max-height: 200px"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Message in English" label-for="textareaw-2">
            <b-form-textarea
              v-model="WelcomeSmsEn"
              :disabled="isDisable"
              id="textareaw-2"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              style="height: 160px; max-height: 200px"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row style="margin-bottom: 10px">
            <b-col>
              <span style="font-weight: bold">VARS</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@1">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="FIRST NAME"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@2">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="LAST NAME"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@3">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="ACCOUNT NUMBER"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@4">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="ACCOUNT NAME"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@5">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="DS PHONE"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="isDisable" lg="12" class="text-center">
          <b-button variant="warning" @click="isDisable = false">
            <feather-icon icon="Edit3Icon"> </feather-icon>
            UPDATE</b-button
          >
        </b-col>
        <b-col v-if="!isDisable" lg="12" class="text-center">
          <b-button variant="primary" class="mr-1" @click="updateMessageWelcome">
            <feather-icon icon="SaveIcon"> </feather-icon>
            SAVE</b-button
          >

          <b-button variant="danger" @click="isDisable = true">
            <feather-icon icon="XIcon"> </feather-icon>
            RETURN</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
    
    <script>
import SettingsService from "@/views/commons/components/settings/services/Settings.service";
import { mapGetters } from "vuex";

export default {
  name: "WelcomeMessage",
  data() {
    return {
      WelcomeSmsEs: null,
      WelcomeSmsEn: null,
      isDisable: true,
    };
  },/*
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },*/
  async created() {
    await this.getMessageWelcome()
  },
  methods: {
    async getMessageWelcome() {
      const data = await SettingsService.getMessageWelcome()
      if (data.status === 200) {
        this.WelcomeSmsEn = data.data[0]['welcome_msg_en']
        this.WelcomeSmsEs = data.data[0]['welcome_msg_es']
      }
    },
    async updateMessageWelcome() {
      const swalConfirm = await this.showConfirmSwal();
      if (swalConfirm.isConfirmed) {
        try {
          const params = {
            welcome_msg_es: this.WelcomeSmsEs,
            welcome_msg_en: this.WelcomeSmsEn,
          };
          const response = await SettingsService.updateMessageWelcome(params);
          if (response.status === 200) {
            this.showSuccessSwal();
            await this.getMessageWelcome();
            this.isDisable = true;
          }
        } catch (e) {
            console.log(e);
            this.showErrorSwal('Check form message!!!', `Invalid Save!`)
        }
      }
    },
  },
};
</script>
    
<style scoped>
</style>