<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4"><h4>Message Offer Reasons</h4></b-col>
        <b-col md="4" offset-md="4" align="right">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="addMotive"
            :disabled="isAdvisor"
          >
            <feather-icon icon="PlusIcon"> </feather-icon>
            ADD</b-button
          >
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Motive Type">
            <v-select
              v-model="motiveType"
              :options="motiveTypes"
              :reduce="(val) => val.id"
              :clearable="false"
              label="type"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Motives">
            <v-select
              v-model="motive"
              :options="offerPaymentOrderMotives"
              :clearable="false"
              label="motive"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <validation-provider
            :rules="`required|max:` + 255"
            v-slot="{ errors }"
            slim
          >
            <b-form-group label="Motive">
              <b-form-textarea
                :disabled="isDisable"
                v-model="motiveSelected.motive"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
                style="height: 100px; max-height: 150px"
                :class="errors[0] ? 'border-danger' : ''"
              ></b-form-textarea>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="isDisable && offerPaymentOrderMotives.length > 0"
          lg="12"
          class="text-center"
        >
          <b-button
            variant="warning"
            @click="isDisable = false"
            :disabled="isAdvisor"
          >
            <feather-icon icon="Edit3Icon"> </feather-icon>
            UPDATE</b-button
          >
        </b-col>
        <b-col v-if="!isDisable" lg="12" class="text-center">
          <b-button variant="primary" class="mr-1" @click="updateOfferMessage">
            <feather-icon icon="SaveIcon"> </feather-icon>
            SAVE</b-button
          >

          <b-button class="mr-1" @click="removeMotive">
            <feather-icon icon="TrashIcon"> </feather-icon>
            REMOVE</b-button
          >

          <b-button variant="danger" @click="isDisable = true">
            <feather-icon icon="XIcon"> </feather-icon>
            RETURN</b-button
          >
        </b-col>
      </b-row>
    </b-card>

    <ModalAddMotive
      v-if="showModalAddMotive"
      :data_modal="{ id: null }"
      @hide="hide"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// 1 is for reject motives and 2 is for pif upload motives
import SettingsService from "@/views/commons/components/settings/services/Settings.service";
import ModalAddMotive from "@/views/commons/components/settings/views/modal/AddMotive.vue";

export default {
  name: "OfferPaymentOrderMessage",
  components: {
    ModalAddMotive,
  },
  data() {
    return {
      motiveTypes: [
        { id: 1, type: "REJECT" },
        { id: 2, type: "PIF" },
      ],
      motiveType: null,
      offerPaymentOrderMotives: [],
      motive: null,
      motiveSelected: {
        id: null,
        motive: "",
      },
      isDisable: true,
      showModalAddMotive: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    isAdvisor() {
      return this.currentUser.role_id === 3;
    },
  },
  watch: {
    motive(newVal) {
      this.motiveSelected = { ...newVal };
    },
    motiveType(newVal) {
      this.getOfferPaymentOrderMotives(newVal);
      this.isDisable = true;
    },
  },
  async created() {
    this.motiveType = 1;
  },
  methods: {
    async hide() {
      (this.showModalAddMotive = false),
        await this.getOfferPaymentOrderMotives(this.motiveType);
    },

    async getOfferPaymentOrderMotives(motiveType) {
      const data = await SettingsService.getOfferPaymentOrderMotives(
        motiveType
      );
      if (data.status === 200) {
        this.offerPaymentOrderMotives = data.data;
        if (this.offerPaymentOrderMotives.length > 0) {
          this.motive = this.offerPaymentOrderMotives[0];
        }
        if (this.offerPaymentOrderMotives.length === 0) {
          this.offerPaymentOrderMotives = [];
          this.motive = null;
          this.motiveSelected = {
            id: null,
            motive: "",
          };
        }
      }
    },
    async updateOfferMessage() {
      try {
        const swalConfirm = await this.showConfirmSwal();
        if (swalConfirm.isConfirmed) {
          const response = await SettingsService.updateOfferPaymentOrderMotive(
            this.motiveSelected
          );
          if (response.status === 200) {
            this.showSuccessSwal();
            await this.getOfferPaymentOrderMotives(this.motiveType);
            this.isDisable = true;
          }
        }
      } catch (e) {
        this.showErrorSwal(e.response.data.message);
      }
    },
    async removeMotive() {
      try {
        const swalConfirm = await this.showConfirmSwal();
        if (swalConfirm.isConfirmed) {
          const response = await SettingsService.removeOfferPaymentOrderMotive(
            this.motiveSelected
          );
          if (response.status === 200) {
            this.showSuccessSwal();
            await this.getOfferPaymentOrderMotives(this.motiveType);
            this.isDisable = true;
          }
        }
      } catch (e) {
        this.showErrorSwal(e.response.data.message);
      }
    },
    async addMotive() {
      this.showModalAddMotive = true;
    },
  },
};
</script>

<style scoped>
</style>
