<template>
  <div>
    <b-card no-body class="px-0">
      <ShippingDaysTable/>
    </b-card>

    
  </div>
</template>
<script>
import ShippingDaysTable from "@/views/commons/components/settings/views/components/ShippingDaysTab/components/ShippingDaysTable.vue";

export default {
  components: { ShippingDaysTable },
};
</script>
