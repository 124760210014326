<template>
<div>
  <b-card
      title="Message Offers"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
            label="Type of Offer"
        >
          <v-select
            v-model="offerType"
            :options="offerTypeOptions"
            :clearable="false"
            label="type"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Message in Spanish" label-for="offer-textarea-1">
          <b-form-textarea
              v-model="offerSmsEs"
              :disabled="isDisable"
              id="offer-textarea-1"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              style="height: 160px; max-height: 200px"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Message in English" label-for="offer-textarea-2">
          <b-form-textarea
              v-model="offerSmsEn"
              :disabled="isDisable"
              id="offer-textarea-2"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              style="height: 160px; max-height: 200px"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row style="margin-bottom: 10px">
            <b-col>
              <span style="font-weight: bold">VARS</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@1">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="FIRST NAME"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@2">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="LAST NAME"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@3">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="ACCOUNT NUMBER"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@4">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="ACCOUNT NAME"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="2 mb-1">
              <b-input-group size="lg" prepend="@5">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="DS PHONE"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="isDisable" lg="12" class="text-center">
          <b-button variant="warning" @click="isDisable = false">
            <feather-icon icon="Edit3Icon"> </feather-icon>
            UPDATE</b-button
          >
        </b-col>
        <b-col v-if="!isDisable" lg="12" class="text-center">
          <b-button variant="primary" class="mr-1" @click="updateOfferMessage">
            <feather-icon icon="SaveIcon"> </feather-icon>
            SAVE</b-button
          >

          <b-button variant="danger" @click="isDisable = true">
            <feather-icon icon="XIcon"> </feather-icon>
            RETURN</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import SettingsService from "@/views/commons/components/settings/services/Settings.service";
import { mapGetters } from "vuex";

export default {
  name: "OffersMessage",
  data() {
    return {
      offerTypeOptions: [],
      offerType: {},
      offerSmsEs: null,
      offerSmsEn: null,
      isDisable: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  watch: {
    offerType: {
      handler(newVal) {
        if (newVal !== null) {
          this.offerSmsEs = newVal.msg_es;
          this.offerSmsEn = newVal.msg_en;
        } else {
          this.offerSmsEs = newVal;
          this.offerSmsEn = newVal;
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getOfferOptions();
  },
  methods: {
    async getOfferOptions() {
      const data = await SettingsService.getOfferOptions();
      if (data.status === 200) {
        this.offerTypeOptions = data.data;
        this.offerType = data.data[0];
      }
    },
    async updateOfferMessage() {
      const swalConfirm = await this.showConfirmSwal();
      if (swalConfirm.isConfirmed) {
        try {
          const params = {
            message_id: this.offerType.id,
            msg_es: this.offerSmsEs,
            msg_en: this.offerSmsEn,
            created_by: this.currentUser.user_id,
          };
          const response = await SettingsService.updateOfferMessage(params);
          if (response.status === 200) {
            this.showSuccessSwal();
            await this.getOfferOptions();
            this.isDisable = true;
          }
        } catch (e) {
          this.showErrorSwal("Please select another option", `Invalid Save!`);
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
