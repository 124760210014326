<template>
  <b-modal
    v-model="show"
    title-class="h3 text-white"
    centered
    size="lg"
    :title="origin == 'binnacle' ? 'Add Message' : 'Add Motive'"
    hide-footer
    modal-class="modal-primary"
    @hidden="hideModal()"
  >
    <validation-observer #default="{ invalid }">
      <b-row>
        <b-col>
          <b-form class="auth-login-form" @submit.prevent="addMotive">
            <b-form-group
              v-if="isEdit == false"
              :label="origin == 'binnacle' ? 'Origin' : 'Motive Type'"
            >
              <validation-provider #default="{ errors }" rules="required">
                <v-select
                  v-model="form.motiveType"
                  :options="getOptions"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  :disabled="origin == 'binnacle' && isEdit"
                  label="type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group :label="origin == 'binnacle' ? 'Message' : 'Motive'">
              <validation-provider
                #default="{ errors }"
                :rules="`required|max:` + 255"
              >
                <b-form-textarea
                  :disabled="isDisable"
                  v-model="form.motive"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  style="height: 100px; max-height: 150px"
                  :class="errors[0] ? 'border-danger' : ''"
                ></b-form-textarea>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              block
              :disabled="invalid"
            >
              <feather-icon icon="SaveIcon"> </feather-icon>
              SAVE</b-button
            >
          </b-form>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import SettingsService from "@/views/commons/components/settings/services/Settings.service";

export default {
  name: "ModalAddMotive",
  props: {
    origin: {
      type: String,
      default: null,
    },
    data_modal: {
      type: Object,
      default: { id: null },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
      form: {
        motive: this.isEdit ? this.data_modal.message : "",
        motiveType: "",
      },
      isDisable: false,
      motiveTypes: [
        { id: 1, type: "REJECT" },
        { id: 2, type: "PIF" },
      ],
      originTypes: [
        { id: 1, type: "Debt-Solution" },
        { id: 2, type: "Management" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getOptions() {
      return this.origin == "binnacle" ? this.originTypes : this.motiveTypes;
    },
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    async addMotive() {
      try {
        this.addPreloader();
        let response;
        if (this.origin == "binnacle") {
          if (this.isEdit) {
            const params = {
              id: this.data_modal.id,
              message: this.form.motive,
              user_id: this.currentUser.user_id,
            };
            response = await SettingsService.updateMessageBinnacle(params);
          } else {
            
            const params = {
              message: this.form.motive,
              user_id: this.currentUser.user_id,
              type: this.form.motiveType,
            };
            response = await SettingsService.insertMessageBinnacle(params);
          }
        } else {
          response = await SettingsService.addOfferPaymentOrderMotive(
            this.form
          );
        }

        if (response.status === 200) {
          this.showSuccessSwal();
          this.$emit("hide", true);
        }
      } catch (e) {
        this.showErrorSwal(e.response.data.message);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>