<template>
  <b-container class="m-0 w-100" fluid>
    <b-card>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Message in Spanish" label-for="textarea-1">
            <b-form-textarea
              id="textarea-1"
              v-model="msg_es"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              style="height: 160px; max-height: 200px"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Message in English" label-for="textarea-2">
            <b-form-textarea
              id="textarea-2"
              v-model="msg_en"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              style="height: 160px; max-height: 200px"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-row style="margin-bottom: 10px">
            <b-col>
              <span style="font-weight: bold">VARS</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6 mb-1">
              <b-input-group size="lg" prepend="@1">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="FIRST NAME"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="6 mb-1">
              <b-input-group size="lg" prepend="@2">
                <b-form-input
                  id="input-1"
                  trim
                  disabled
                  placeholder="LAST NAME"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" class="text-center">
          <b-button variant="primary" @click="save">
            <feather-icon icon="SaveIcon"> </feather-icon>
            SAVE</b-button
          >
        </b-col>
      </b-row>
    </b-card>

    <offer-payment-order-message v-if="moduleId === 5" />
    <!-- <FinancialAnalysisSMS v-if="moduleId === 5" /> -->
    <!-- <messages-binnacle /> -->
    <offers-message v-if="!isSpecialist" />
    <WelcomeMessage/>
  </b-container>
</template>

<script>
import SettingsService from "@/views/commons/components/settings/services/Settings.service.js";
import { mapGetters } from "vuex";
import OffersMessage from "@/views/commons/components/settings/views/components/OffersMessage.vue";
import OfferPaymentOrderMessage from "@/views/commons/components/settings/views/components/OfferPaymentOrderMessage.vue";
import WelcomeMessage from "@/views/commons/components/settings/views/components/WelcomeMessage.vue";
//import FinancialAnalysisSMS from "@/views/commons/components/settings/views/components/FinancialAnalysisSMS.vue";
// import MessagesBinnacle from "@/views/commons/components/settings/views/components/MessagesBinnacleCrud/MessagesBinnale.vue";

export default {
  components: {
    OffersMessage,
    OfferPaymentOrderMessage,
    WelcomeMessage,
    //FinancialAnalysisSMS,
    // MessagesBinnacle,
  },
  data() {
    return {
      msg_es: "",
      msg_en: "",
    };
  },
  async created() {
    await Promise.all([this.getSetting()]);
  },
  methods: {
    async save() {
      try {
        const body = {
          msges: this.msg_es,
          msgen: this.msg_en,
          program: this.program,
          type: 1,
        };
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const res = await SettingsService.getSetting(body);
          if (res.status === 200) {
            this.msg_es = res.data[0].msg_es;
            this.msg_en = res.data[0].msg_en;
            this.showSuccessSwal();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSetting() {
      try {
        const body = {
          program: this.program,
          type: 0,
        };
        const res = await SettingsService.getSetting(body);
        if (res.status === 200) {
          this.msg_es = res.data[0].msg_es;
          this.msg_en = res.data[0].msg_en;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    program() {
      return this.convertoModuleToProgramC(this.moduleId);
    },
    isSpecialist() {
      return this.moduleId === 11;
    },
  },
};
</script>

<style></style>
